/**
 * middleware: andLiffRedirect
 *
 * 用途： 有一些 AND 舊的 LIFF 網址過來，會因為 LIFF ID 問題發生一些網址轉向問題，這裡是用來 workaround 那些問題的作法
 */
export default async function andLiffRedirect({ next }) {
  // 如果網址路徑是 AND 奇妙 LIFF 過來的網址，就把它連同 liff.state 解開後，組成完整路徑存到 localStorage
  const andPathPattern = /^\/.*\/liff\/proj\/.*-and\/(rewards-card\/card_list|coupon\/my|j1|j2|j3|j4|ipd)$/;

  if (andPathPattern.test(location.pathname)) {
    const liffState = new URLSearchParams(location.search).get('liff.state');
    if (liffState) {
      const originTargetUrl = location.origin + location.pathname + liffState
      console.debug("[AND LIFF REDIRECT] originTargetUrl", originTargetUrl);
      localStorage.setItem('AND_LIFF_REDIRECT', originTargetUrl);
    }
  }

  return next();
}
