import preorderApi from "@/apis/liff/v2/preorder";
import _ from "lodash";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cart: {}, // key: sku id, value: quantity
    skus: [],
    configuration: null,
  },
  getters: {
    totalQuantity({ cart }) {
      return _.chain(cart).values().sum().value();
    },
  },
  actions: {
    async fetchConfiguration(context, preorderId) {
      context.state.configuration = _.get(await preorderApi.getConfiguration(preorderId), "data.data", []);
    },
    async fetchSkus(context, { preorderId, branchId }) {
      context.state.skus = _.get(await preorderApi.getSkus(preorderId, branchId), "data.data", []);
    },
    updateCartQuantity(context, { skuId, quantity }) {
      if (quantity === 0) {
        Vue.delete(context.state.cart, skuId);
      } else {
        Vue.set(context.state.cart, skuId, quantity);
      }
    },
    clearCart(context) {
      Object.keys(context.state.cart).forEach(skuId => {
        Vue.delete(context.state.cart, skuId);
      });
    },
  },
};
