export default {
  namespaced: true,
  state: {
    submittedBooking: null,
    viewingBookingPreset: null,
  },
  actions: {
    async setSubmittedBooking({ state }, booking) {
      state.submittedBooking = booking
    },
    async setViewingBookingPreset({ state }, bookingPreset) {
      state.viewingBookingPreset = bookingPreset
    },
  },
};
