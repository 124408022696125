import https from "./https";
import store from "@/store";

const form = {
  getConfiguration(preorderId) {
    return https.get(`${store.state.liffGeneral.orgCode}/liff/preorders/${preorderId}`);
  },
  getSkus(preorderId, branchId) {
    return https.get(`${store.state.liffGeneral.orgCode}/liff/preorders/${preorderId}/skus`, { params: { branch_id: branchId } });
  },
  create(preorderId, data) {
    // NOTE: example body format
    // {
    //   "fields": {
    //     "00a91632-a70c-4e87-a3ab-6847a0523c6c": "王小明",
    //     "67058639-1ed5-4fe9-9fb8-ab0927ed0bd4": "0911222333",
    //     "3a758e90-16d5-48ee-b7c2-f3f49651ec13": "我希望有紙袋包裝",
    //   },
    //   "items": [
    //     {
    //       "sku_id": "03jmy5z4248g",
    //       "quantity": 1
    //     },
    //     {
    //       "sku_id": "yo39xdlrmrwg",
    //       "quantity": 2
    //     }
    //   ],
    //   "branch_id": "xjlmlpx62g1y"
    // }
    return https.post(`${store.state.liffGeneral.orgCode}/liff/preorders/${preorderId}/create`, data);
  },
};

export default form;
